<template>
  <v-card>
    <v-col
      cols="12"
      class="pl-3 pr-5"
    >
      <v-row>
        <v-spacer />

        <v-col
          cols="3"
          sm="2"
          md="2"
          class="pr-3"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="date"
                label="Tarih"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="2"
          sm="4"
          md="2"
          class="pr-3"
        >
          <v-dialog
            ref="dialog"
            v-model="hourmodal2"
            :return-value.sync="hourmodel"
            persistent
            width="290px"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="hourmodel"
                label="Saat"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="hourmodal2"
              v-model="hourmodel"
              full-width
              :allowed-minutes="allowedStep"
              format="24hr"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="hourmodal2 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(hourmodel)"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="2"
          sm="2"
          md="2"
          class="pr-3"
        >
          <v-select
            v-model="santralModel"
            :items="santralList"
            label="Santral"
          />
        </v-col>

        <v-col
          cols="3"
          sm="1"
          md="1"
          class="pr-4 pt-3"
        >
          <v-btn
            color="primary"
            @click="getInverter"
          >
            Filtrele
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="items_per_page"
        dense
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:item.CommStatusCode="{item}">
          <div v-if="item.CommStatusCode > 0">
            <i
              style="font-size: 20px"
              class="mdi mdi-checkbox-marked-circle green--text"
            />
          </div>

          <div v-else>
            <i
              style="font-size: 20px"
              class="mdi mdi-checkbox-marked-circle red--text"
            />
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
import * as Inverter from '@/api/Inverter/Inverter';
import moment from 'moment';
import i18n from '@/locale';

export default {
  data: () => ({
    headers: [],
    desserts: [],
    santralModel: [],
    items_per_page: 10,
    date: moment().format('YYYY-MM-DD'),

    menu: false,
    modal: false,

    componentKey: 1,
    hourmenu: false,
    hourmodel: moment().format('HH:mm'),
    time: null,
    menu2: false,
    hourmodal2: false,
    step_time: 5,
    santralInfo: [],
    inverterData: [],
  }),
  computed: {
    santralList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      return santralDataList;
    },
  },
  watch: {
    hourmodel() {},
    santralModel() {
      this.setInverter();
    },
  },
  created() {
    this.hourmodel = moment().format('HH:mm');
    const minute = parseInt(moment().format('mm'));

    let minuteGrup = 0;
    for (let mng = 0; mng < 60; mng += 10) {
      if (minute >= mng) {
        minuteGrup = mng;
      }
    }
    let m;

    minuteGrup < 10 ? (m = `0${minuteGrup}`) : (m = minuteGrup);
    this.hourmodel = moment().format('HH:') + m;
  },
  mounted() {
    this.headers = [
      {
        text: i18n.t('general.connection'),
        value: 'CommStatusCode',
        width: '5 px',
      },
      { text: 'INVERTER', value: 'INVERTERNAME' },
      { text: 'INVERTER', value: 'INVERTER', display: 'none' },
      { text: 'Manufacture', value: 'Manufacture' },
      { text: 'Model', value: 'Model' },
      { text: 'Seri Nu.', value: 'Serial' },

      {
        text: `${i18n.t('TheoreticalPower')}(kW)`,
        value: 'TheoreticalPower',
      },
      {
        text: `${i18n.t('PerformanceRatio')}(%)`,
        value: 'PerformanceRatio',
      },
      { text: 'Efficiency', value: 'Efficiency' },
      {
        text: `${i18n.t('ACPower')}(kW)`,
        value: 'ACPower',
      },
      {
        text: `${i18n.t('Temperature')}(°C)`,
        value: 'TempBoard',
      },
      { text: 'LifeTime' + '(dk)', value: 'LifeTime' },
      {
        text: `${i18n.t('DailyEnergy')}(kW)`,
        value: 'DailyEnergy',
      },
      {
        text: `${i18n.t('TotalEnergy')}(kW)`,
        value: 'TotalEnergy',
      },
    ];

    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const StatsMeasure = self.$store.getters.santralInfo;
      const { santral } = self.$store.getters.selectedSantralModel;

      if (
        Object.keys(StatsMeasure).length > 0
        && StatsMeasure !== undefined
        && santral !== undefined
      ) {
        self.santralModel = santral;
        self.santralInfo = StatsMeasure;
        clearInterval(myVar1);
      }
    }
  },
  methods: {
    allowedMinutes: (v) => v >= 10 && v <= 50,
    allowedStep: (m) => m % 10 === 0,
    getInverter() {
      const santralDataList = this.$store.getters.santralInfo;
      this.getInverterList(santralDataList[this.santralModel]);
    },
    setInverter() {
      const selecetedInverter = this.santralInfo[this.santralModel].INVERTER;
      const inverterData = [];
      Object.keys(selecetedInverter).forEach((item) => {
        inverterData.push(selecetedInverter[item]);
      });
      this.inverterData = inverterData;

      this.getInverterList();
    },

    getInverterList() {
      const selecetedInverter = this.santralInfo[this.santralModel].INVERTER;
      const inverterList = Object.keys(selecetedInverter);
      const column = [];
      this.headers.forEach((item) => {
        column.push(item.value);
      });

      this.items_per_page = inverterList.length;
      const formdata = {
        condiniton: {
          santral: this.santralModel,
          Time: this.date,
          hour: this.hourmodel,
          inverterList,
          limit: inverterList.length,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          column,
        },
      };
      const tempData = Inverter.default.getInverter(formdata);
      this.desserts = [];

      tempData.then((res) => {
        if (res.length > 0) {
          const restt = {};
          Object.keys(res).forEach((item) => {
            if (res[item].INVERTER !== undefined) {
              restt[res[item].INVERTER] = res[item];
            }
          });
          const desserts = this.inverterData;
          Object.keys(desserts).forEach((item) => {
            let source = {
              ACPower: 0,
              CommStatusCode: 0,
              DailyEnergy: 0,
              Efficiency: 0,
              LifeTime: 0,
              TheoreticalPower: 0,
              PerformanceRatio: 0,
              TempBoard: 0,
              TotalEnergy: 0,
            };

            if (restt[desserts[item].INVERTER] !== undefined) {
              const selectedval = restt[desserts[item].INVERTER];
              source = {
                ACPower: selectedval.ACPower,
                CommStatusCode: selectedval.CommStatusCode,
                DailyEnergy: selectedval.DailyEnergy,
                Efficiency: selectedval.Efficiency,
                LifeTime: selectedval.LifeTime,
                TheoreticalPower: selectedval.TheoreticalPower,
                PerformanceRatio: selectedval.PerformanceRatio,
                TempBoard: selectedval.TempBoard,
                TotalEnergy: selectedval.TotalEnergy,
              };
            }
            const target = desserts[item];
            desserts[item] = Object.assign(target, source);
          });
          this.desserts = desserts;
        }
        this.componentKey++;
      });
    },
  },
};
</script>

<style scoped>
</style>
